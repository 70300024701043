<template>
  <div>
    <div class="if-form">
      <span class="title">期刊名称：</span>
      <y-input v-model="query" clearable placeholder="模糊查询" @on-change="onQueryChange"></y-input>
      <!-- <y-select
        clearable
        @on-query-change="onQueryChange"
        filterable
        placeholder="模糊查询"
        :loadMethod="loadJournalList"></y-select> -->
    </div>
    <div class="if-table">
      <y-table :columns="columns" :data="journalList" :height="tableHeight"></y-table>
    </div>
    <div class="if-pagination">
      <pagination :total="sum" :pageIndex="pageIndex" :pageSize="pageSize" @on-currentpage-change="onPageChange"></pagination>
    </div>
  </div>
</template>
<script>
import YInput from '../common/input/input'
import YTable from '../common/table/table'
import Pagination from '../common/pagination/pagination'
import columns from './mixins/columns'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: { YInput, YTable, Pagination },
  mixins: [columns],
  data () {
    return {
      query: '',
      journalList: [],
      pageIndex: 0,
      pageSize: 50,
      sum: 0,
      showLoading: false
    }
  },
  computed: {
    tableHeight () {
      return this.$store.getters.getWinHeight - 60 - 130 - (window.$upgrade ? 22 : 0)
    }
  },
  mounted () {
    document.title = 'SCI影响因子查询'
    this.loadJournalList()
  },
  methods: {
    loadJournalList () {
      this.$http.get(`${this.httpRoot}/journal/list`, authenticate({
        params: {query: this.query, pageIndex: this.pageIndex, pageSize: this.pageSize}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.journalList = res.journalList || []
          this.sum = res.sum || 0
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    onPageChange (page) {
      this.pageIndex = page - 1
      this.loadJournalList()
    },
    onQueryChange (val) {
      this.pageIndex = 0
      this.query = val
      this.loadJournalList()
    }
  }
}
</script>
<style lang="less">
.if-form {
  padding: 20px;
  &> .title {
    float: left;
    &+ div.cu-input-wrapper {
      float: left;
      width: 400px;
    }
  }
  &:after {
    content: '.';
    height: 0;
    display: block;
    visibility: hidden;
    clear: both;
  }
}
.if-table {
  padding: 0 20px;
}
.if-pagination {
  padding-top: 10px;
}
</style>
