export default {
  data () {
    return {
      columns: [
        {
          type: 'index',
          title: '序号',
          width: 65,
          align: 'center'
        },
        // {
        //   type: 'selection',
        //   title: '',
        //   width: 65,
        //   align: 'center'
        // },
        {
          title: '期刊名称',
          key: 'journal',
          width: 400
        },
        {
          title: '影响因子',
          key: 'impactFactor',
          width: 130,
          sortable: true
        },
        {
          title: '中科院分区',
          key: 'quality',
          width: 130,
          sortable: true
        },
        {
          title: '大类学科',
          key: 'majorSubject',
          width: 100
        },
        {
          title: '小类学科',
          key: 'smallSubject',
          width: 150
        },
        // {
        //   title: '随访日期',
        //   key: 'followupDate',
        //   formatter: (val) => { return val ? new Date(Date.regDate(val)).format('yyyy-MM-dd') : '' },
        //   width: 150,
        //   align: 'center',
        //   toggle: true,
        //   toggleShow: true
        // },
        // {
        //   title: '状态',
        //   key: 'status',
        //   formatter: (val) => { return val === 'new' ? '新随访' : val === 'doing' ? '随访中' : val === 'done' ? '已完成' : '&nbsp;' },
        //   align: 'center',
        //   width: 100,
        //   toggle: true,
        //   toggleShow: false
        // },
        {
          title: '录用比例',
          key: 'acceptRatio',
          width: 100
        },
        {
          title: '审稿周期',
          key: 'reviewCycle',
          width: 130
        },
        {
          title: '热度',
          key: 'heatDegree',
          width: 65,
          align: 'center'
        }
        // {
        //   title: '操作',
        //   key: 'action',
        //   width: 150,
        //   align: 'center',
        //   render: (h, params) => {
        //     return h('span', [
        //       h('button', {
        //         attrs: {
        //           class: 'table-actions-btn'
        //         },
        //         style: {
        //           marginRight: '15px'
        //         },
        //         on: {
        //           click: () => { this.lookup(params) }
        //         }
        //       }, '查看'),
        //       h('button', {
        //         attrs: {
        //           class: 'table-actions-btn'
        //         },
        //         on: {
        //           'click': () => { this.remove(params) }
        //         }
        //       }, '删除')
        //     ])
        //   }
        // }
      ]
    }
  }
}
